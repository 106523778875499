import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import keycloak from './keycloak'
import axios from 'axios'

const app = createApp(App)

// Set up Axios interceptors globally
axios.interceptors.request.use(
  async (config) => {
    try {
      await keycloak.updateToken(30)  // Ensure the token is valid
      config.headers.Authorization = `Bearer ${keycloak.token}`
    } catch (error) {
      console.error('Failed to refresh Keycloak token', error)
      keycloak.logout()
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

keycloak.init({ onLoad: 'login-required' }).then((authenticated) => {
  if (authenticated) {
    app.use(router)
    app.use(vuetify)
    app.mount('#app')
  } else {
    window.location.reload()
  }

  // Token refresh
  setInterval(() => {
    keycloak.updateToken(70).catch(() => {
      keycloak.logout()
    })
  }, 60000)
}).catch(() => {
  console.log('Failed to initialize Keycloak')
})

