import { createRouter, createWebHistory } from 'vue-router'
import EventList from '../views/EventList.vue'
import UploadEvent from '../views/UploadEvent.vue'
import EditEvent from '../views/EditEvent.vue'
import UploadGallery from '../views/UploadGallery.vue'
import UploadEventImages from '../views/UploadEventImages.vue'
import keycloak from '../keycloak'

const routes = [
  {
    path: '/',
    name: 'EventList',
    component: EventList
  },
  {
    path: '/event/info',
    name: 'UploadEvent',
    component: UploadEvent
  },
  {
    path: '/edit/:id',
    name: 'EditEvent',
    component: EditEvent,
    props: true
  },
  {
    path: '/gallery/upload',
    name: 'UploadGallery',
    component: UploadGallery
  },
  {
    path: '/event/images',
    name: 'UploadEventImages',
    component: UploadEventImages
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

// Protect all routes
router.beforeEach((to, from, next) => {
  if (keycloak.authenticated) {
    next()
  } else {
    keycloak.login()
  }
})

export default router

